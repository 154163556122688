import { createContext, useContext, useEffect } from "react";

const MixpanelContext = createContext(null as any);
export default MixpanelContext;

export const MixpanelConsumer = MixpanelContext.Consumer;
export const MixpanelProvider = MixpanelContext.Provider;

export const useMixpanel = () => useContext(MixpanelContext);

export const useMixpanelTrackEvent = (name: string, context?: any) => {
  const mixpanel = useMixpanel();
  useEffect(() => {
    // mixpanel.track(name, context);
  }, []);
};
