import { createContext, ReactNode, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from "../@types/auth";
// import {
//   addOrganization,
//   setUserOrganizations,
//   clearOrganizations,
// } from "src/redux/slices/organization";
import { Organization, OrganizationMember } from "src/@types/organization";
import { dispatch as globalDispatch } from "../redux/store";
import { clearOrganizationState } from "src/redux/slices/organization";
import { clearUserState, userOrganizationInvitesAdded } from "src/redux/slices/user";

// ----------------------------------------------------------------------

export enum AuthContextTypes {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
  Update = "UPDATE",
  AddOrg = "ADD_ORG"
}

type JWTAuthPayload = {
  [AuthContextTypes.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    orgs: any;
  };
  [AuthContextTypes.Login]: {
    user: AuthUser;
    orgs: any
  };
  [AuthContextTypes.Logout]: undefined;
  [AuthContextTypes.Register]: {
    user: AuthUser;
  };
  [AuthContextTypes.Update]: {
    user: AuthUser;
  };
  [AuthContextTypes.AddOrg]: {
    org: any
  }
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  orgs: []
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        orgs: action.payload.orgs
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        orgs: action.payload.orgs
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        orgs: null
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        orgs: []
      };

    case "UPDATE":
      return {
        ...state,
        user: action.payload.user
      };

    case "ADD_ORG":
      return {
        ...state,
        orgs: [...state.orgs, action.payload.org]
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/account");
          const { user, orgs, invites } = response.data;

          // globalDispatch(setUserOrganizations(orgs));
          globalDispatch(userOrganizationInvitesAdded(invites));


          dispatch({
            type: AuthContextTypes.Initial,
            payload: {
              isAuthenticated: true,
              user,
              orgs
            },
          });
        } else {
          dispatch({
            type: AuthContextTypes.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              orgs: null
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: AuthContextTypes.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            orgs: null
          },
        });
      }
    };

    initialize();
  }, []);

  const loadAccount = async () => {
    const response = await axios.get("/account");
    const { user, orgs, invites } = response.data;

    globalDispatch(userOrganizationInvitesAdded(invites));

    dispatch({
      type: AuthContextTypes.Initial,
      payload: {
        isAuthenticated: true,
        user,
        orgs
      },
    });
  };

  const login = async (email: string, password: string) => {
    const loginResponse = await axios.post("/account/login", {
      email,
      password,
    });
    const { accessToken } = loginResponse.data;

    setSession(accessToken);

    // TODO - move this to the Login Response on backend!
    const accountResponse = await axios.get("/account");
    const { user, orgs, invites } = accountResponse.data;

   // orgs.forEach((org: Organization) => globalDispatch(organizationAdded(org)));
    // globalDispatch(setUserOrganizations(orgs));
    globalDispatch(userOrganizationInvitesAdded(invites));

    dispatch({
      type: AuthContextTypes.Login,
      payload: {
        user,
        orgs
      },
    });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post("/account/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user, invites } = response.data;

    setSession(accessToken);
    globalDispatch(userOrganizationInvitesAdded(invites));

    dispatch({
      type: AuthContextTypes.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    await axios.post("/account/logout");
    setSession(null);
    globalDispatch(clearOrganizationState());
    globalDispatch(clearUserState());
    dispatch({ type: AuthContextTypes.Logout });
  };

  const addOrg = (org: any) => {
    dispatch({type: AuthContextTypes.AddOrg, payload: { org }})
  }

  const updateUserGeneral = async (
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    about: string,
    photoURL: boolean
  ) => {
    const response = await axios.patch("/account", {
      email,
      firstName,
      lastName,
      phoneNumber,
      about,
      photoURL,
    });
    const { user } = response.data;

    dispatch({
      type: AuthContextTypes.Update,
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        updateUserGeneral,
        loadAccount,
        addOrg
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
