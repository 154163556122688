import axios from 'axios';
// config
import { HOST_API } from '../config';
import { setSession } from './jwt';
import { dispatch, dispatch as globalDispatch } from "../redux/store";
import { AuthContextTypes } from 'src/contexts/JWTContext';
import { clearOrganizationState } from 'src/redux/slices/organization';
import { clearUserState } from 'src/redux/slices/user';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

//axiosInstance.defaults.headers.common['x-api-key'] = HOST_API_KEY;


export default axiosInstance;

export const membersQuery = (id: string) => axiosInstance.get(`/organization/${id}/members`).then((res) => res.data?.members)
export const orgQuery = (id: string) => axiosInstance.get(`/organization/${id}`).then((res) => res.data?.org);