import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { UserOrganizationIncomingInvite, UserState } from "src/@types/user";


const inviteAdapter = createEntityAdapter<UserOrganizationIncomingInvite>();

const initialInviteState = inviteAdapter.getInitialState({
    loading: false
});

const initialState: UserState = {
    invites: initialInviteState
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserState(state) {
            inviteAdapter.removeAll(state.invites);
        },
        userOrganizationInvitesAdded(state, action) {
            inviteAdapter.upsertMany(state.invites, action);
        },
        userOrganizationInviteRemoved(state, action){
            inviteAdapter.removeOne(state.invites, action);
        }
    },
    extraReducers: builder =>
        builder

});

export const {
    userOrganizationInvitesAdded,
    userOrganizationInviteRemoved,
    clearUserState
} = slice.actions;

export default slice.reducer;
