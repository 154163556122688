import { Suspense, lazy, ElementType, useEffect } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// guards
import useAuth from "../hooks/useAuth";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import CreateOrganization from "src/pages/CreateOrganization";
import OrganizationHome from "src/pages/dashboard/organization/OrganizationHome";
import OrganizationMembers from "src/pages/dashboard/organization/OrganizationMembers";
import OrganizationInviteMember from "src/pages/dashboard/organization/OrganizationInviteMember";
import OrganizationAccount from "src/pages/dashboard/organization/OrganzationAccount";
import OrganizationSubOrgs from "src/pages/dashboard/organization/OrganizationSubOrgs";
import { PayInvoice } from "src/pages/dashboard/invoice/PayInvoice";
import { OrganizationManageRoles } from "src/pages/dashboard/organization/OrganizationManageRoles";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
import { AccessPermission } from "src/@types/organization";
import NewPassword from "src/pages/auth/NewPassword";
import OrganizationPayments from "src/pages/dashboard/organization/OrganizationPayments";
import OrganizationCreatePayment from "src/pages/dashboard/organization/OrganizationCreatePayment";
import { PaymentReceipt } from "src/pages/dashboard/invoice/PaymentReceipt";
import { OrganizationsExplorePage } from "src/pages/dashboard/organizations/OrganizationsExplorePage";
import { TermsAndConditions } from "src/pages/TermsAndConditions";
import { PrivacyPolicy } from "src/pages/PrivacyPolicy";
import { useMixpanel } from "src/contexts/MixpanelContext";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  
  return useRoutes([
    // {
    //   path: "auth",
    //   children: [
    //     {
    //       path: "login",
    //       element: (
    //         <GuestGuard>
    //           <Login />
    //         </GuestGuard>
    //       ),
    //     },
    //     {
    //       path: "register",
    //       element: (
    //         <GuestGuard>
    //           <Register />
    //         </GuestGuard>
    //       ),
    //     },
    //     { path: "reset-password", element: <ResetPassword /> },
    //     { path: "verify", element: <VerifyCode /> },
    //     { path: 'new-password', element: <NewPassword /> },
    //   ],
    // },

    // Dashboard Routes
    // {
    //   path: "dashboard",
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: "app", element: <GeneralApp /> },
    //     {
    //       path: "user",
    //       children: [
    //         {
    //           element: <Navigate to="/dashboard/user/account" replace />,
    //           index: true,
    //         },
    //         // { path: "cards", element: <UserCards /> },
    //         // { path: "list", element: <UserList /> },
    //         // { path: "new", element: <UserCreate /> },
    //         { path: "account", element: <UserAccount /> },
    //       ],
    //     },
    //     {
    //       path: "payment/:id/pay",
    //       element: <PayInvoice />,
    //     },
    //     {
    //       path: "payment/:id/receipt",
    //       element: <PaymentReceipt/>
    //     },
    //     {
    //       path: "organizations/explore",
    //       element: <OrganizationsExplorePage></OrganizationsExplorePage>
    //     },
    //     {
    //       path: "organization/:id",
    //       children: [
    //         { element: <Navigate to="home" />, index: true },
    //         { path: "home", element: <OrganizationHome /> },
    //         {
    //           path: "members",
    //           element: (
    //             <RoleBasedGuard
    //               accessiblePermissions={[AccessPermission.READ_MEMBERS]}
    //             >
    //               <OrganizationMembers />
    //             </RoleBasedGuard>
    //           ),
    //         },
    //         {
    //           path: "members/invite",
    //           element: (
    //             <RoleBasedGuard
    //               accessiblePermissions={[AccessPermission.MANAGE_MEMBERS]}
    //             >
    //               <OrganizationInviteMember />
    //             </RoleBasedGuard>
    //           ),
    //         },
    //         {
    //           path: "members/manage-roles",
    //           element: (
    //             <RoleBasedGuard
    //               accessiblePermissions={[AccessPermission.MANAGE_MEMBERS]}
    //             >
    //               <OrganizationManageRoles />
    //             </RoleBasedGuard>
    //           ),
    //         },
    //         {
    //           path: "account",
    //           element: (
    //             <RoleBasedGuard
    //               accessiblePermissions={[AccessPermission.MANAGE_ORGANIZATION]}
    //             >
    //               <OrganizationAccount />
    //             </RoleBasedGuard>
    //           ),
    //         },
    //         {
    //           path: "payments",
    //           element: (
    //             <RoleBasedGuard
    //               accessiblePermissions={[AccessPermission.MANAGE_PAYMENTS]}
    //             >
    //               <OrganizationPayments />
    //             </RoleBasedGuard>
    //           ),
    //         },
    //         {
    //           path: "payments/create",
    //           element: (
    //             <RoleBasedGuard
    //               accessiblePermissions={[AccessPermission.MANAGE_PAYMENTS]}
    //             >
    //               <OrganizationCreatePayment/>
    //             </RoleBasedGuard>
    //           ),
    //         },
    //         {
    //           path: "sub-orgs",
    //           element: (
    //             <RoleBasedGuard
    //               accessiblePermissions={[AccessPermission.MANAGE_SUBORGS]}
    //             >
    //               <OrganizationSubOrgs />
    //             </RoleBasedGuard>
    //           ),
    //         },
    //       ],
    //     },
    //   ],
    // },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        // { path: "about-us", element: <About /> },
        { path: "help-center", element: <HelpCenter /> },
        // { path: "payment", element: <Payment /> },
        // { path: "pricing", element: <Pricing /> },
        {path: 'terms-and-conditions', element: <TermsAndConditions/>},
        {path: 'privacy-policy', element: <PrivacyPolicy/>},
        // {
        //   path: "create-organization",
        //   element: (
        //     <AuthGuard>
        //       <CreateOrganization />
        //     </AuthGuard>
        //   ),
        // },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const HelpCenter = Loadable(lazy(() => import("../pages/HelpCenter")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
