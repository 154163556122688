import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { PaymentMethod, PayoutAccount } from "src/@types/billing";
import { Organization, OrganizationMember, OrganizationState, OrganizationOutgoingInvite, OrganizationPayment, OrganizationInvoice } from "src/@types/organization";
import axiosInstance from "src/utils/axios";
import axios from "src/utils/axios";


const organizationAdapter = createEntityAdapter<Organization>();

const inviteAdapter = createEntityAdapter<OrganizationOutgoingInvite>();
const paymentMethodAdapter = createEntityAdapter<PaymentMethod>();
const payoutAccountAdapter = createEntityAdapter<PayoutAccount>();
const paymentHistoryAdapter = createEntityAdapter<OrganizationPayment>();
const invoiceAdapter = createEntityAdapter<OrganizationInvoice>();

const initialOrganizationState = organizationAdapter.getInitialState({
  loading: false
});

const initialInviteState = inviteAdapter.getInitialState({
  loading: false
});
const initialPaymentMethodState = paymentMethodAdapter.getInitialState({
  loading: false
});
const initialPaymentHistoryState = paymentHistoryAdapter.getInitialState({
  loading: false
});

const initialState: OrganizationState = {
  invites: initialInviteState,
  paymentMethods: initialPaymentMethodState,
  paymentHistory: initialPaymentHistoryState,
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    clearOrganizationState(state) {
      inviteAdapter.removeAll(state.invites);
      paymentMethodAdapter.removeAll(state.paymentMethods);
      paymentHistoryAdapter.removeAll(state.paymentHistory);
      INVITES_LOADED_BY_ORG_ID.clear();
      PAYMENT_METHODS_LOADED_BY_ORG_ID.clear();
      PAYMENT_HISTORY_LOADED_BY_ORG_ID.clear();
    },
    invitesAdded(state, action) {
      inviteAdapter.upsertMany(state.invites, action);
    },
    paymentMethodsAdded(state, action) {
      paymentMethodAdapter.upsertMany(state.paymentMethods, action);
    },
    paymentMethodDeleted(state, action) {
      paymentMethodAdapter.removeOne(state.paymentMethods, action);
    }
  },
  extraReducers: builder =>
    builder
      // Invites
      .addCase(fetchInvites.pending, (state) => {
        state.invites.loading = true;
      })
      .addCase(fetchInvites.fulfilled, (state, action) => {
        inviteAdapter.upsertMany(state.invites, action);
        state.invites.loading = false;
      })
      // Payment Methods
      .addCase(fetchPaymentMethods.pending, (state, action) => {
        state.paymentMethods.loading = true;
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        paymentMethodAdapter.upsertMany(state.paymentMethods, action);
        state.paymentMethods.loading = false;
      })
      // Payment History
      .addCase(fetchPaymentHistory.pending, (state) => {
        state.paymentHistory.loading = true;
      })
      .addCase(fetchPaymentHistory.fulfilled, (state, action) => {
        paymentHistoryAdapter.upsertMany(state.paymentHistory, action);
        state.paymentHistory.loading = false;
      })
});

export const {
  clearOrganizationState,
  invitesAdded,
  paymentMethodsAdded,
  paymentMethodDeleted
} = slice.actions;

export default slice.reducer;

export const selectInvitesByOrganization = (state: EntityState<OrganizationOutgoingInvite>, orgId: string) => {
  const invites: OrganizationOutgoingInvite[] = [];
  state.ids.forEach(id => {
    if (state.entities[id]?.orgId === orgId) {
      invites.push(state.entities[id]!);
    }
  });
  return invites;
};

export const selectPaymentMethodsByOrganization = (state: EntityState<any>, orgId: string) => {
  const paymentMethods: PaymentMethod[] = [];
  state.ids.forEach(id => {
    if (state.entities[id]?.orgId === orgId) {
      paymentMethods.push(state.entities[id]!);
    }
  });
  return paymentMethods;
};


export const selectPaymentHistoryByOrganization = (state: EntityState<any>, orgId: string) => {
  const paymentHistory: OrganizationPayment[] = [];
  state.ids.forEach(id => {
    if (state.entities[id]?.orgId === orgId) {
      paymentHistory.push(state.entities[id]!);
    }
  });
  return paymentHistory;
};



const INVITES_LOADED_BY_ORG_ID = new Set<string>();
export const fetchInvites = createAsyncThunk('organization/invites/loadById',
  async ({ orgId, forceReload }: { orgId: string, forceReload?: boolean; }, { getState, dispatch }) => {

    // Cached
    if (INVITES_LOADED_BY_ORG_ID.has(orgId) && !forceReload) {
      return [];
    }

    try {
      const response = await axios.get(`/organization/${orgId}/invites`);
      const { invites } = response.data;
      INVITES_LOADED_BY_ORG_ID.add(orgId);

      return invites;
    } catch (e) {
      // Error
      return [];
    }
  });

const PAYMENT_METHODS_LOADED_BY_ORG_ID = new Set<string>();
export const fetchPaymentMethods = createAsyncThunk('organiation/cards/loadById',
  async ({ orgId, forceReload }: { orgId: string, forceReload?: boolean; }, { getState, dispatch }) => {

    if (PAYMENT_METHODS_LOADED_BY_ORG_ID.has(orgId) && !forceReload) {
      return [];
    }

    try {
      const response = await axiosInstance.get(`/organization/${orgId}/billing/payment-method`);
      const { cards } = response.data;
      PAYMENT_METHODS_LOADED_BY_ORG_ID.add(orgId);

      return cards.map((card: any) => {
        return {
          ...card,
          orgId
        };
      });
    } catch (e) {
      // Error
    }

  });


const PAYMENT_HISTORY_LOADED_BY_ORG_ID = new Set<string>();
export const fetchPaymentHistory = createAsyncThunk('organiation/paymentHistory/loadById',
  async ({ orgId, forceReload }: { orgId: string, forceReload?: boolean; }, { getState, dispatch }) => {

    if (PAYMENT_HISTORY_LOADED_BY_ORG_ID.has(orgId) && !forceReload) {
      return [];
    }

    try {
      const response = await axiosInstance.get(`/organization/${orgId}/billing/payment-history`);
      const { paymentHistory } = response.data;
      PAYMENT_HISTORY_LOADED_BY_ORG_ID.add(orgId);

      return paymentHistory.map((payment: any) => {
        return {
          ...payment,
          orgId
        };
      });
    } catch (e) {
      // Error
    }
  });


