import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import organizationReducer from './slices/organization';
import userReducer from './slices/user';


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  organization: organizationReducer,
  user: userReducer,
});

export { rootPersistConfig, rootReducer };
